@import "src/styles/variables";
$banner-height: 48px;

.subscriptionBar {
    background: #FEEFD6;
    color: #C98C25;
    position: fixed;
    top: 0;
    width: 100%;
    height: $banner-height;
    padding: 0.75rem 1rem;
    transition: 0.2s;
    cursor: pointer;
    z-index: 998;

    &:hover {
        background-color: #FEE2B4;

    }
}