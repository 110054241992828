@import "src/styles/variables";


.container {
    height: 100%;
    width: 100%;
    // background: $black;

    @include media-breakpoint-up(md) {
        position: relative;
        background: #FFFFFF;
        backdrop-filter: blur(25px);
        /* Note: backdrop-filter has minimal browser support */
        overflow: hidden;
        border-radius: 24px;
    }

    &.close {
        display: block;


    }

    .form {
        background: $white;
        height: 100dvh;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: 100%;
            border-radius: 0;
            margin: 0;
        }
    }

    .headerWrapper {
        font-size: 1.25rem;
        font-weight: 700;
        height: 64px;
        background: #FFFFFF;
        /* Black 5% */

        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        /* button shadow/secondary */

        padding: 0 1rem;
    }


    &.modal {
        height: 100dvh;
        @include media-breakpoint-up(md) {
           height: 100%;
        }
        .form {

            display: flex;
            flex-direction: column;
            justify-content: space-between;

        }
    }
}


.toWrapper {
    margin: 10px 20px;
    padding: 10px 0;
    height: 80px;
    border-bottom: 1px solid rgba(18, 10, 32, 0.05);

    .to {
        margin-right: 1rem;

        span {
            font-size: 0.75rem;
            color: #6b6b6b;
            line-height: 1;
            margin-right: 0.375rem;
            font-weight: 400;
            margin-left: 6px;
        }

        .tag {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 1;
            /* identical to box height */

            display: flex;
            align-items: center;
            justify-content: center;
            /* Violet 05 */

            color: #834EFF;
        }

        div {
            padding: 8px;
            height: 33px;
            background: #F9F4FF;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;


        }
    }
}

.subjectArea {
    margin: 0 20px;
    padding: 10px 0;
    border-bottom: 1px solid rgba(18, 10, 32, 0.05);

    .subject {
        div {
            margin-bottom: 0;
        }

        input {
            border: 0 !important;
            padding: 0;

            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: rgba(18, 10, 32, 0.3);
            }
        }

        .tiny {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;

            /* Black 50% */

            color: rgba(18, 10, 32, 0.5);
        }
    }
}

.message {
    margin: 1.5rem 0;

    textarea {
        border: 0 !important;
        height: 200px;
        resize: none;
        border-radius: 0.5rem;

        &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: rgba(18, 10, 32, 0.3) !important;
        }
    }
}

.optIns {
    margin-top: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    /* Black 50% */

    color: rgba(18, 10, 32, 0.5);

    svg {
        margin-right: 0.375rem;
    }
}

.width100 {
    width: 100%;
}

.positionRelative {
    position: relative;
}

.cancel {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    width: 32px;
    height: 32px;

    /* White */

    background: #FFFFFF;
    /* Black 5% */

    border: 1px solid rgba(0, 0, 0, 0.05);
    /* button shadow/secondary */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    span {
        color: black;
    }
}

.disclaimer {
    color: #a3a3a3;
    font-size: 0.75rem;
    margin: 1rem 0 0;

    svg {
        margin-right: 0.5rem;
    }
}

.error {
    color: $danger;
    font-size: 14px;
    padding: 1rem 0;
    text-align: center;

    .errorLink {
        color: $danger;
        text-decoration: underline;

        &:hover {
            color: $danger;
        }
    }
}

.creditsOverlay {
    background-color: $white;
    border-radius: 24px;
}

.enableSmsLoading {
    position: relative;

    [class*="Loading-module_PicoLoader_modal"] {
        height: 30px;
        width: 30px;
        margin-top: -37px;
    }


    [class*="child"] {
        padding: 0;
    }
}


.header {
    display: flex;
    align-items: center;

    .title {
        margin-bottom: 0;
        /* h3 */

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;

        /* Black */

        color: #120A20;
    }

    svg {
        margin-right: 12px;
    }
}

.sendButton {
    /* Auto layout */
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;

    /* White */

    color: #FFFFFF;
    /* Purple Message Gradient */

    background: linear-gradient(180deg, #9B70FF 0%, #693ECD 100%);
    /* Black 5% */

    border: 1px solid rgba(0, 0, 0, 0.05);
    /* button shadow/primary */

    box-shadow: 0px 1px 2px rgba(122, 52, 255, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;

    &:disabled {
        opacity: 0.7;
    }
}

.footer {
    width: 100%;
    padding: 10px 20px;

    .item {
        cursor: pointer;
        margin-right: 12px;
        line-height: 1;
    }
}

.enablePhoneButton {
    box-sizing: border-box;
    border: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 8px;

    height: 40px;


    background: linear-gradient(180deg, #9B70FF 0%, #693ECD 100%);

    border: 1px solid rgba(0, 0, 0, 0.05);

    box-shadow: 0px 1px 2px rgba(122, 52, 255, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;



    color: #FFFFFF;

}

.wizardContents {
    padding: 1rem;

    @include media-breakpoint-up(md) {
        min-height: 410px;

        &.claimed {
            margin-top: 0 !important;
        }
    }

    &.claimed {
        margin-bottom: 25%;
    }

    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */


        /* Black 70% */

        color: rgba(18, 10, 32, 0.7);
    }

    .searchWrapper {

        overflow: auto;
        margin-top: 8px;
        max-height: calc(100vh - 250px);

        @include media-breakpoint-up(md) {
            max-height: 300px;
        }

        .searchOption {
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 12px 16px;
            gap: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */


            /* Black */

            color: #120A20;

            /* Gray 02 */

            background: #FAFAFA;
            border-radius: 8px;

            &.active {
                border-inline: 1px solid #9B70FF;
            }
        }
    }
}

.phoneNumber {
    margin-top: 24px;

    span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        /* identical to box height, or 112% */
        margin-left: 8px;

        /* Black */

        color: #120A20;
    }
}

.formWrapper {
    height: 100svh;

    @include media-breakpoint-up(sm) {
        height: auto;
    }
}

.option {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    /* or 138% */


    /* Black */

    color: #120A20;
}

.typeButton {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: transparent;

    button {
        background-color: transparent !important;
        box-shadow: none;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */
        padding: 0;
        display: flex;
        align-items: center;
        text-align: center;

        /* Violet 05 */

        color: #834EFF;

        svg {
            fill: #834EFF;
            margin-right: 8px;
        }

        border: 0;

        &:focus {
            box-shadow: none;
            color: #834EFF;

        }
    }

    .menuClass {
        width: 161px;
        bottom: 52px;
        left: 0;
    }
}

.draft {
    @include media-breakpoint-up(md) {
        display: none;
    }

}

.draftButton {
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
        margin-right: 8px;
        border: 0;
        background-color: transparent;
        outline: none;
        box-shadow: none;

        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        text-align: center;

        /* Violet 05 */

        color: #834EFF;
    }
}

.imageWrapper {
    position: relative;
    display: inline;

    .image {
        width: 64px;
        height: 64px;
        border-radius: 5.12px;
        margin-left: 20px;
    }

    svg {
        position: absolute;
        top: -15px;
        right: 3px;
    }
}

.overflow {
    button {
        padding: 0;
        width: 20px;
        height: 20px;
        border: 0;
        background-color: transparent;
        box-shadow: none;
        outline: none;
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.overflowMenu {
    color: #834EFF;

}

.selected {
    box-shadow: inset 0px 0px 0px 2px #834EFF;
}