@import 'src/styles/variables';

.tooltip {
  font-size: $tooltip-font-size;
  color: $tooltip-color;
  background-color: $tooltip-bg;
  border-radius: $tooltip-border-radius;
  padding: $tooltip-padding-y $tooltip-padding-x;
  margin: $tooltip-margin;
}
