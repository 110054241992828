@import 'src/styles/variables';

.wrapper {
  // margin-top: auto;
  // margin-bottom: 2rem;
  // border: 2px solid $gray-400;
  border-radius: 40px;
  // padding: 2px;
  @include media-breakpoint-down(md) {
    border: none;
    border-top: 1px solid $gray-400;
    border-radius: 0;
    width: 100%;
    padding: 1.25rem 1rem 2rem;
    margin-bottom: 0;
    margin-top: 0;
  }
  .item {
    min-width: 220px;
    svg{
      fill: black
    }
  }
  .input {
    max-width: 300px;
  }
  .account {
    padding: 1rem;
    @include media-breakpoint-down(md) {
      padding: 0rem;
      .marginLeft {
        margin-left: 0.75rem;
      }
    }
  }
  .communityName {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    @include media-breakpoint-up(md) {
      overflow: hidden;
      white-space: normal;
      width: auto;
    }
  }
  .accountName {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $gray-700;
    margin-bottom: 0;
  }
  :global {
    .icon-chevron-down {
      display: none;
    }
    .btn:first-child {
      box-shadow: 0 0 0 0 $gray-400;
      border: none;
      border-radius: 40px;
      // height: 40px;
      // width: 40px;
      outline: none;
      padding: 0;
      margin-bottom: 1rem;
      &:focus {
        outline: none;
        box-shadow: none;
      }
      @include media-breakpoint-down(md) {
        box-shadow: none;
        border: none;
        border-radius: 0;
        width: 100%;
        justify-content: flex-start;
        margin-bottom: 0;
      }
    }
  }
  div[class^='Overflow_menu'] {
    bottom: 0.5rem;
    left: 5rem;
    width: 240px;
    @include media-breakpoint-down(md) {
      bottom: 6.25rem;
      left: 1rem;
      max-width: 100%;
    }
  }
}
.switchAccountWrapper {
  position: absolute;
  left: 248px;
  bottom: 0;
  background-color: $white;
  padding: 0.25rem;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15);
  border-radius: $border-radius;
}
.searchWrapper {
  width: 300px;
  height: 540px;
  display: flex;
  flex-direction: column;
  div[class^='FormSearch'] {
    padding: 0.5rem;
    button {
      font-size: 12px;
      font-weight: 500;
      box-shadow: none;
      margin-right: 0.15rem;
      div {
        display: flex;
        align-items: center;
        justify-self: center;
      }
    }
  }
}

.optionWrapper {
  position: relative;
  width: 100%;
  height: 500px;
  overflow-y: scroll;

  .navLink {
    height: 44px;
    margin: 5px 10px;
    .optionAvatar {
      margin-right: 5px;
    }
    .button {
      height: 40px;
    }
  }
}

.redColor {
  color: $red;
}

.bigIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.menuAvatar{
  margin-left: 0.75rem;
}
.icon{
  color: black;
  margin-right: 0.400rem !important;
  &:hover {

    path {
      fill: white;

    }

  }

  svg{
    margin-left: -0.375rem;
    margin-top: -0.25rem;

  }
}
.positionRelative{
  position: relative;
}
.switchIcon {
  font-size: 24px;
  margin: 0.5rem;
}
.switchAccountName{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}


.switchAccountUsername{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  color: #AAA7B0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.option {
  &:hover {
    span {
      svg {
        path {
          fill: white;

        }
      }
    }
  }
}

.activeSwitch{
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  background-color: rgba(238, 237, 239, 1);

  &:hover {
    background-color: rgba(238, 237, 239, 1) !important;
    color: black !important;
  }
}
.searchOption {
  &:hover {
    // background-color: rgba(238, 237, 239, 1);
  }
}
.option{
  align-items: center;
  padding: 0.5rem !important;
}