@import 'src/styles/variables';

.container{
    h2 {
        margin-top: 1rem;
    }
    button {
        margin: 1rem 0;
    }
}
