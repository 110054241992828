@import 'src/styles/variables';

@mixin containerWidth {
  width: calc(100% - 2.5rem);
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(lg) {
    width: calc(100% - 72px);
  }
}

@mixin container {
  @include containerWidth;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 1.5rem;
  padding: 2.5rem 0;

  @include media-breakpoint-up(lg) {
    margin: 2rem auto;
    padding: 2.5rem 1.5rem;
  }
}
