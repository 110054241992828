@import 'src/styles/variables';

.button {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 99999;
    display: flex;
    align-items: center;
    p {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .icon {
        width: 30px;
        height: 30px;
        font-size: 31px;
        color: $primary;
        -webkit-animation-duration: 1.75s;
        animation-duration: 1.75s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-name: rotate-forever;
        animation-name: rotate-forever;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }




}

@-webkit-keyframes rotate-forever {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-forever {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}