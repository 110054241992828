@import "src/styles/variables";

.cancel {
  &:global{
    &.btn{
      border-radius: 8px;
      border: 1px solid $gray-500;
      &:local {
        &.link {
          border: 0;
          box-shadow: none;
          cursor: pointer;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
.footer {
  div {
    width: 100%;
  }
  &.widthAuto {
    div {
      width: auto;
    }
  }
  &.error {
    div {
      width: 100%;
    }
  }
}

.action {
  margin-left: 20px;
}
.checkbox {
  margin-bottom: 12px;
  div {
    border-radius: 4px;
  }
}
.refundContainer {
  margin-left: 20px;
}
.radio {
  margin-bottom: 5px;
}
.tiny {
  line-height: 1.5;
  padding-left: 25px;
  margin-top: -10px;
  display: block;
  margin-bottom: 10px;
}
.labelContainer {
  .name {
    font-size: 0.875rem;
    color: $black;
    font-weight: 600;
    text-transform: none;
  }
  .createdAt {
    font-size: 0.875rem;
    color: $navbar-light-disabled-color;
    font-weight: 400;
    text-transform: none;
  }
}

.container {
  :global {
    .react-select__single-value {
      p {
        max-width: 100%;
      }
      small {
        display: none;
      }
    }
  }
  &.grantFreeAccessContainer{
    width: 432px;
    :global{
     .react-select__menu-list{
       max-height: 180px;
     }
    }
    .header{
        border-radius: $border-radius $border-radius 0 0;
    }
    .datepicker{

        :global{
            .DateRangePicker{
                width: 100%;
                .DateRangePickerInput{
                    width: 100%;
                }
            }
        }
    }
    }
  .option {
    margin-bottom: 0;

    .optionLabel {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      max-width: 250px;
      font-size: 1rem;
    }

    &:hover {
      .optionLabel {
        margin-bottom: 0;
      }
    }

    .price {
      margin-bottom: 0;
      margin-right: 10px;
      color: $navbar-light-disabled-color;
      font-size: 0.875rem;
    }

    &.last {
      &:after {
        width: 400px;
        height: 1px;
        background-color: black;
      }
    }
  }
}

.spacing {
  margin: 0 0 1.5rem;

  span {
    display: none;
  }
}

.currency {
  &_field {
    position: relative;
    height: 46px;
    margin-bottom: 2rem;

    input[name="refund_amount"] {
      padding: 0.375rem 1rem 0.375rem 2rem;
    }
  }

  &_label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1rem;
    margin: auto;
    height: max-content;
  }
}
.dropdownScroll{
  :global{
    .react-select__menu-list{
      max-height: 100px;
    }
  }
}
