@import 'src/styles/variables';

.popupContainer{
    padding: 1.5rem 2rem 2rem;
}

.popup{
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @include media-breakpoint-up(sm) {
        max-width: 432px;
    }

    > div {
        background-color: transparent;
        margin: 2rem 0;
    }
}
