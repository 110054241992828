@import 'src/styles/variables';

.stripeUsage {
    .stripeExpressIssue {
        color: red; 
    }
    p {
      color: rgba($black, 0.7)
    }

    .btn-dangerous {
        button { 
            span {
                display: none;
            }
        }
    }
}

.gotoDashboard {
    margin-bottom: 1rem;
}