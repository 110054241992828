@import 'src/styles/variables';

// attempt to make the footer sticky
.fillHeight {
    height: 100%;
    background-color: $gray-200;
}

$banner-height: 48px;

.showBanner {
    div[class*='AppBar_mainNavBar'],
    div[class*='Menu_mainNavBar'],
    div[class*='navigation_leftNavbar'] {
        transition: top 0.01s;
        top: $banner-height;
    }
    div[class*='navigation_menu'],
    div[class*='Menu_menu'],
    div[class*='LoggedInScaffolding_main'],
    div[class*='ActionsMenu_wrapper'],
    div[class*='MessagesMenu_messages_container'] {
        top: calc(64px + $banner-height);
    }
    div[class*='LPEditor_topEditorMobileMenu'] {
        margin-top: calc(46px + $banner-height);
    }
    div[class*='OfferContext_content'] {
        height: calc(100vh - 81px - 72px - $banner-height);
    }
    @include media-breakpoint-down(md) {
        h2[class*='OfferContext_heading'] {
            margin-top: calc(46px + $banner-height);
        }
    }
    @include media-breakpoint-up(lg) {
        div[class*='LoggedInScaffolding_main'],
        div[class*='MessagesMenu_messages_container'] {
            top: calc(72px + $banner-height);
        }
        div[class*='EditorScaffolding_main'] {
            margin-top: calc(72px + $banner-height);

            div[class*='EditorScaffolding_layout'] {
                height: calc(100vh - 72px - $banner-height);
            }
        }
    }
}