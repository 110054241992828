.wrapper {
    cursor: pointer;
    border: 1.5px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    margin: 0;
    flex: 1;
    &.active {
        border: 1.5px solid #834eff;
        box-shadow: inset 0px 0px 0px 3px #ffffff;
        .child {
            background-color: #f9f4ff;
        }
    }
    .child {
        border-radius: 7px;
        width: 100%;
        height: 100%;
        border: 1.5px solid white;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .option {
        display: none;
    }
}
