@import "src/styles/variables";

.titlebar{
    background: $gray-300;
    border-radius: 12px 12px 0px 0px;
    padding: 1.5rem 2rem;

    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: $black;

    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
        padding: 1rem 1.25rem;
    }
}