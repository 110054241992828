@import 'src/styles/variables';

.noMargin {
    margin: 0;
}

.noPadding {
    padding: 0;
}
.container {
    padding: 1.25rem;
    @include media-breakpoint-up(md) {
        padding: 1.5rem 2rem;
    }
    > div {
        width: 100%;
    }
}

.leftMargin {
    margin-left: .5rem;
}
.leftPadding {
    padding-left: 1rem;
}

.header {
    min-height: 4.5rem;
    padding-left: .25rem;
    padding-right: .25rem;
    @include media-breakpoint-up(md) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .head {
        font-size: 1.25rem;
        margin-bottom: 0;
    }
    &.center {
        justify-content: center;
    }
}
.wrapper {
    border-radius: .3rem;
    top: 50%;
    transform: translate(0, -50%);
    overflow-x: hidden;
    @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-right: auto;
        width: (calc(100% - 2rem));
    }


    &.narrow{
        max-width: 400px;
    }
    div {
        // overflow: auto;
        .fullWidth {
            width: 100%;
            display: block;
            border-radius: .3rem .3rem 0 0;
        }
    }
    &.loading {
        div {
            border-radius: 50%;
        }
    }
}

.defaultFooter {
    padding: 0 2rem 2rem;
    width: 100%;
}

.narrowFooter {
    padding: 0 2rem 1.5rem;
    width: 100%;
}

.full { 
    width: 100%;
    margin: 0;
}
.danger {
  background-color: #ffefee;
  color: $red;
}
.info {
  background-color: $gray-300;
  color: #110824;
}

.small {
  min-height: 4.5rem;
  .head {
      font-size: 1rem;
  }
}
