@import 'src/styles/variables';

.topTableBar {
    background-color: $navbar-light-active-bg-color;
    height: 78px;
    padding: 0 24px;
    .selectedText {
        color: $black;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
    }
    .link {
        color: $primary;
        font-size: 1rem;
        cursor: pointer;
        margin-left: 6px;
        margin-bottom: 0;
    }
    .button {
        margin: 0 8px;
        border-radius: 8px;
        border: 1px solid $gray-500;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
        &.delete {
            color: $danger;
        }
    }
}

.dropdownHeader {
    margin-bottom: 0;
    color: #110824;
    font-size: 1rem;
    font-weight: $font-weight-base;
}
.icon {
    font-size: 1rem;
}
.withIcon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.unbold {
    margin-left: 12px;
    font-weight: $font-weight-base;
    background-color: $white;
    border: 1px solid #DDDCE0;
    border-radius: 0.5rem;
    button{
      height: 36px;
    }
}
.danger {
    color: $danger;
}
.wrapper {
    background-color: $white;
    border-radius: 8px;
}
.clearButton {
    text-decoration: none;
    cursor: pointer;
    color: $primary;
    margin-left: 1rem;
}
.checkboxContainer{
  margin-bottom: 1.5rem;
  .child{
    height: 27px;
  }
}
.checkbox{
  margin-bottom: 0;
}
.iconDollar{
  color: $gray-700;
  font-size: 1rem;
}
.verify {
    background: $gray-300;
    border-radius: .28571429rem;
    margin: 0 -.75rem;
    padding: 1rem .75rem 0;
}
.newsletterListWrapper {
    max-height: calc(100vh - 350px);
    overflow-y: scroll;
}
.modal {
  > div {
    z-index: 1041;
  }
}
.cancel{
  &:global{
    &.btn{
      margin-top: 1rem;
      box-shadow: none;
    }
  }
}
.action{
  &:global{
    &.btn{
      margin-top: 1rem;
      box-shadow: none;
    }
  }
}
.activityDetailContainer {
  width: 100%;
  padding: 12px;
  flex-wrap: wrap;
  .item {
    .icon {
      font-size: 1.5rem;
      margin-right: 10px;
    }
  }
}
.detail{
  font-size: 14px;
  font-weight: 400;
  color: black;
  &.bold{
    font-weight: 600;
  }
}
.link{
  font-size: 14px;
  font-weight: 400;
}
.toggleButton{
  padding: 0 !important;
  font-size: 14px !important;
  text-decoration: none !important;
}

.disabled {
  cursor: not-allowed;
  color: $gray-700;

  &:hover {
    background: none;
    color: $gray-700;;
  }
}
