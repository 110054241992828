@import 'src/styles/variables';
.form{
    @include media-breakpoint-down(md) {
        padding-top: 72px;
        width: 100%;
    }
    .field {
        margin-bottom: 0;
        margin-right: 2rem;
        min-width: 180px;
        
        @include media-breakpoint-down(md) {
            min-width: 100%;
            width: 100%;
            margin: 0;
            div{
                border-radius: 0;
            }
        }
    }
}