@import "src/styles/variables";

.terms{
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: $gray-700;
    margin-top: 1.25rem;
    margin-bottom: 1.5rem;
}