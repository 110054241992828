@import "src/styles/variables";

.form{
    position: relative;
    margin-bottom: 1.5rem;

    .input{
        position: relative;
        width: 100%;
        overflow: hidden;

        padding: 12px 20px;

        height: 44px;

        background: #FFFFFF;

        border: 1px solid $gray-500;
        border-radius: 8px;

        &:not(.noPlaceholder){
            &::placeholder{
                color: $gray-500;
            }
        }

        &, & input{
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.25rem;
            color: $black;
        }
    }

    .cardNumber{
        position: relative;

        & > .brand{
            position: absolute;
            top: 50%;
            width: 3.75rem;
            font-size: 20px;
            transform: translateY(-50%);
            z-index: 1;

            & , & > div{
                display: flex;
                justify-content: center;
                align-items: center;
            }

            + .input{
                padding-left: 3.75rem;
            }
        }
    }

    .flex{
        display: flex;
        width: 100%;

        .input{
            margin-top: 8px;
            margin-bottom: 8px;
            &:first-child{
                margin-right: 4px;
            }

            &:last-child{
                margin-left: 4px;
            }
        }
    }
}