@import "src/styles/variables";

.popupContainer {
    overflow-y: auto;
    padding: 1.5rem 2rem 2rem 2rem;

    @include media-breakpoint-down(md) {
        padding: 1.5rem 1.25rem;
    }
}

.popup{
    max-width: 432px !important;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 2rem) !important;

    & div[class*='modalContent'] {
        border-radius: 0.75rem;
    }
}
