@import 'src/styles/variables';

.popup{
    max-width: 432px !important;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 2rem) !important;
    background-color: $white;
    border-radius: 0.75rem;

    & div[class*='modalContent'] {
        border-radius: 0.75rem;
    }

    .popupDesc {
        padding-bottom: 1.5rem;
    }
    .icon {
        font-size: 25px;
        padding: 0;
    }
}

.popupContainer {
    overflow-y: auto;
    padding: 1.5rem 2rem 1.5rem 2rem;

    @include media-breakpoint-down(md) {
        padding: 1.5rem 1.25rem;
    }
}

.button {
    width: 100%;
}

.button:nth-of-type(1){
    margin-bottom: 0.75rem;
}


.titlebar{
    background: $gray-300;
    border-radius: 12px 12px 0 0;
    padding: 1.5rem 2rem;

    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: $black;

    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
        padding: 1rem 1.25rem;
    }
}
