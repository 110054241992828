
.option {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    /* or 138% */


    /* Black */

    color: #120A20;
}