@import 'src/styles/variables';

.planCreditSummary {
    position: relative;
}

.planCredits {
    color: $gray-800;
}

.progressBar {
    margin-top: 2rem;
    position: relative;
}

.planCreditsUsed {
    color: $gray-800;
    font-weight: 500;
    font-size: 0.875rem;
    margin-top: 0.5rem;

    @include media-breakpoint-up(md) {
        text-align: right;
    }
}