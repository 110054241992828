@import "src/styles/variables";

.wrapper {
    position: relative;
    display: inline-block;
}

.button {
    cursor: pointer;
    background-color: white !important;
    padding: 13px 8px 13px 18px;
    border: $border-width solid #DDDCE0;
    border-radius: $border-radius;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    display: flex;
    min-width: 38px;
    &.icon {
        padding: 0 8px;
    }
}
@each $color, $value in $theme-colors {
  .#{$color} {
    @include badge-variant($value);
  }
}


.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    svg {
        transition: transform 0.1s linear;
    }

    &.open {
        svg {
            transform: rotate(180deg);
        }
    }
}

.menu {
    display: none;
    position: absolute;
    z-index: 20;
    background-color: white;
    padding: 4px;
    min-width: 212px;
    right: 0;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
    border-radius: $border-radius;
    width: 100%;

    @include media-breakpoint-up(lg) {
        width: max-content;
    }

    @include media-breakpoint-down(sm) {
        padding: 4px 0;
        min-width: auto;
    }
    &.left{
      right: 0;
    }
    &.right{
      left: auto;
    }
    &.top {
      bottom: 0;
    }
    &.bottom {
      top: 45px;
    }
    &.open {
        display: block;
        min-width: auto;
        width: max-content;
    }
}

.option {
    padding: 12px 20px;
    border-radius: $border-radius-sm;
    font-size: $font-size-base;
    cursor: pointer;
    width: 100%;
    font-family: $font-family-sans-serif;
    display: flex;
    justify-content: flex-start;

    @include media-breakpoint-down(sm) {
        padding: 10px 16px;
    }

    &:hover {
        background: #834eff;
        color: white;
        div[class*="newLabel"] {
            background: white;
            color: #834eff;
        }
    }
    &.form {
        &:hover {
            color: black;
            background-color: white;
        }
    }
}

.optionIcon {
    margin-right: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.25rem;
    @include media-breakpoint-down(sm) {
        margin-right: 0.625rem;
    }
}

.hr {
    margin: 12px 0 12px -4px;
    width: calc(100% + 8px);
    height: 1px;
    background-color: #dddce0;
}
