.notEnoughCredit {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 12px;


    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    
    /* Red 06 */
    
    color: #CC3633;
    /* Red 01 */

    background: #FFEFEE;
    /* Black 5% */

    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.upgradeButton {
    margin-top: 1.5rem;
}

.link {
    background: #834eff;
    border-color: rgba(18, 10, 32, 0.05);
    box-shadow: 0 1px 2px rgba(131, 78, 255, 0.15), 0 1px 2px rgba(18, 10, 32, 0.15);
    width: 100%;
    text-align: center;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 8px;
    &:hover{
        color: white;
        text-decoration: none;
        background: rgba(148, 92, 255, 0.8);

    }
}