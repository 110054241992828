@import 'src/styles/variables';

.footer {
    div {
        width: 100%;
    }
}

.small {
    color: rgba($black, 0.7);
    display: block;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
}

.switchContainer {
    margin: 1rem 0;

    .labelText {
        font-size: 16px;
        line-height: 1;
        display: block;
        margin: 0 0 0 0.5rem;
    }
}