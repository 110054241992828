@import "src/styles/mixins";
@import "src/styles/variables";

.secondary {
    font-size: 1.125rem;
    color: $gray-700;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }


.heading {
  font-size: 1.5rem;
  max-width: 80%;

  @include media-breakpoint-down(md) {
    font-size: 1.125rem;
  }
}

.secondaryLink {
    font-size: 1.125rem;
    color: $gray-700;
    margin-right: 1rem;

    @include media-breakpoint-up(md) {
      margin-right: 0;
    }

    &:hover {
      color: $gray-900;
      text-decoration: none;
    }
}

.main {
  background: $gray-300;
  bottom: 0;
  left: 0;
  right: 0;
  top: 64px;
  overflow: auto;
  position: fixed;
  transition: 0.2s;

  @include media-breakpoint-up(lg) {
    left: 72px;
    top: 72px;
    bottom: 0;
  }
}
.title {
    color: $gray-700;
    span {
        color: $gray-400;
        margin: 0 12px;
    }
}
.divider {
  display: none;

  @include media-breakpoint-up(md) {
    display: inline-block;
  }
}
.name {
    color: $black;
    margin-bottom: 0;
    font-size: 1.125rem;
    word-break: break-word;
    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
      color: $black;
      font-weight: bold;
      margin-right: 1rem;
    }
}

