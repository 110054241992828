@import 'src/styles/variables';

.header {
  padding: 30px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  .column {
    margin: auto;
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin: auto;
    }
    .button {
      width: 2rem;
      height: 2rem;
      border: 1px solid #DDDCE0;
      box-shadow: none;
      margin-left: auto;
    }
    .closeIcon{
      width: 14px;
      height: 14px;
    }
  }
}

.container {
  height: calc(100vh - 313px); // subtract height of header, top nav, modal header, & bottom nav
  overflow: auto;
  padding: 24px;

  @include media-breakpoint-up(md) {
    height: auto;
    overflow: none;
  }
  .title{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .block {
    margin-top: 2rem;
    .title{
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: rgba(18, 10, 32, 0.7);
      line-height: 20px;
    }

  }

  .footer{
    padding: 16px 0 0 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    .title{
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #77747C;
    }
    .value {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #120A20;
      text-align: right;
    }
  }

}

.creditPopup{
  min-height: auto;
}