@import 'src/styles/variables';

.themeListing {
    display: flex;
    position: relative;
    left: 0;
    width: 100%;

    [class*="icon-admin"] {
        align-items: center;
        background: $white;
        border-radius: 16px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
        display: flex;
        height: 20px;
        justify-content: center;
        right: 6px;
        top: 6px;
        width: 20px;
    }

    [class*="glider"][class*="draggable"] {
        &::-webkit-scrollbar-thumb {
            background-color: inherit !important;
        }

        [class*="glider-track"] {
            $theme-width: 129px;
            padding: 0 0.75rem;
            width: calc($theme-width * 11 + 1.5rem) !important;
            
            @include media-breakpoint-up(lg) {
                padding: 0 1.25rem;
                width: calc($theme-width * 11 + 2.5rem) !important;
            }

            [class*="glider-slide"] {
                min-width: $theme-width;
                width: $theme-width !important;
            }
        }
    }
}
