@import "src/styles/variables";

.disabled {
    cursor: not-allowed;
    color: $gray-700;

    &:hover {
        background: none;
        color: $gray-700;;
    }
}

.option-content {
    display: flex;
    justify-content: space-between;
}

.locked {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.paywallPopup {
    max-width: 95vw !important;
    width: calc(100% - 2rem);
    height: calc(100% - 1rem);
    top: 1rem;
    left: 0rem;

    @include media-breakpoint-down(md) {
        width: 100% !important;
        left: 0;
    }

    [class*="ModalContainer-module_modalContent"] {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        height: 100%;
    }

    .title {
        flex-shrink: 0;
    }

    .minimumPlan {
        background: #FFFAF1;
        border-radius: 0.75rem;
        font-weight: 500;
        margin-bottom: 1.5rem;
        padding: 1.5rem;
    }

    .freeTrial {
        padding: 1rem 0 2rem 0;
        text-align: center;
    }

    p {
        font-weight: normal;
        &.messagingAllowance {
            font-weight: 500;
            margin: 0;
        }
        &.description {
            margin-top: 1rem;
        }
    }

    .price {
        margin: 0;
    }

    // Mobile Layout
    .planCard {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
        border-radius: 0.75rem;
        font-size: 14px;
        margin-bottom: 2rem;
        padding: 1.5rem;
        @include media-breakpoint-up(lg) {
            display: none;
        }

        .showFeaturesToggle {
            margin-left: -0.5rem;

            .chevron {
                margin-left: 0.25rem;
                transition: 0.2s;

                &.open {
                    -webkit-transform: rotate(180deg);
                       -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                         -o-transform: rotate(180deg);
                            transform: rotate(180deg);
                }
            }
        }

        .planCardFeatures {
            border-top: 1px solid $gray-500;
            margin-top: 2rem;
            table-layout: fixed;
            width: 100%;

            tr:first-child td {
                padding-top: 2rem;
            }

            td:first-child {
                font-weight: 500;
                width: 65%;
            }
        }
    }

    // Desktop Layout
    .planTable {
        height: 100%;
        table-layout: fixed;
        width: 100%;
        font-size: 14px;
        @include media-breakpoint-down(md) {
            display: none;
        }

        thead, th {
            height: 100%;
        }
    
        th, td {
            border-right: 1px solid $gray-500;
            padding: 0.75rem 1rem;
            position: relative;
        }
    
        th {
            padding: 2.5rem 1rem;

            &:first-child {
                width: 16rem;
                @include media-breakpoint-down(lg) { width: 10rem; }
            }

            &:not(:first-child) {
                border-top: 1px solid $gray-500;
                max-height: 420px;
                // max-width: 20rem;
                // min-width: 9.25rem;
                vertical-align: top;
        
                &:nth-child(4) {
                    // background: #F9F4FF;
                }
        
                >div[class*="FlexboxContainer"] {
                    height: 100%;
                }
        
                button {
                    margin-bottom: 0;
                }
            }
        }
    
        .bubble {
            border: 1px solid $gray-500;
            border-radius: 0.75rem;
            font-size: 14px;
            font-weight: 700;
            height: 2rem;
            left: 50%;
            letter-spacing: 0.5px;
            line-height: 1.5rem;
            margin-left: -3rem;
            padding: 0.25rem;
            position: absolute;
            text-align: center;
            top: -1rem;
            width: 6rem;
        }

        .popularBg {
            background: #E6DBFF;
        }
    
        .activeBg {
            background: #e2ffdb;
        }
    
        .minimumBg {
            background: #dbf6ff;
        }
    
        tr:nth-child(odd) {
            background: $gray-300;
            td:nth-child(4) {
                // background: #E6DBFF;
            }
        }
    
        tr:nth-child(even) td:nth-child(4) {
            // background: #F9F4FF;
        }
    
        tr:last-child {
            td:not(:first-child) {
                border-bottom: 1px solid $gray-500;
            }
        }
    
        td:first-child {
            width: 16rem;
            @include media-breakpoint-down(lg) { width: 10rem; }
        }

        // .popularColumn{
        //     background: #F9F4FF;
        // }
    }
}
