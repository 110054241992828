@import 'src/styles/variables';

.creditsText {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  
  
  /* Black 50% */
  
  color: rgba(18, 10, 32, 0.5);
  

}

.estimate {
  display: flex;
  align-items: center;
  justify-content: center;
  &.error{
    p{
    color: #FF4440;
  }
  svg{
    fill: #FF4440;
  }
  }
}

.icon {
  margin-left: 5px;
  line-height: 1;
  &:hover {
    cursor: pointer;
  }
}
