@import "src/styles/variables";

.summary{
    display: flex;
    flex-direction: column;

    .list{
        .summary-item{
            &:last-child{
                padding-top: 0.75rem;
                border-top: 1px solid $gray-500;
            }
        }
    }

    .summary-item{
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.75rem;

        .name{
            display: flex;
            flex-direction: column;

            font-weight: 700;
            font-size: 1rem;
            line-height: 1.25rem;
            color: $black;

            small{
                margin-top: 4px;
                font-size: 14px;
                line-height: 17px;
                color: $gray-700;
            }
        }

        .price{
            display: flex;
            align-items: flex-start;

            font-weight: 700;
            font-size: 1rem;
            line-height: 1.25rem;
            color: $black;

            &.colorGreen{
                color: $green;
            }
        }
    }
}
