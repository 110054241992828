@import 'src/styles/mixins';
@import 'src/styles/variables';

.iconContainer {
  margin: 0.25rem 0 0 0.5rem;

  .icon {
    color: $gray-700;
  }
}
