@import 'src/styles/variables';

.block {
    background-color: white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 48px;
    margin: 32px 0;

    @include media-breakpoint-down(lg) {
      padding: 1.25rem;
    }

    @include media-breakpoint-up(lg) {
        border-radius: 0.75rem;
        padding: 2rem;
    }

    >*:last-child {
        margin-bottom: 0;
    }
}

.currency{
  max-width: 120px;
}
