@import "src/styles/variables";

.summary{
    display: flex;
    align-items: center;
    margin-top: 0.5rem;

    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: $black;

    .icon{
        font-size: 1.25rem;
        margin-right: 0.5rem;
    }

    span{
        border-right: 1px solid $gray-500;
        padding-right: 0.625rem;
    }

    button{
        height: auto !important;
    }
}