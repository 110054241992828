@import 'src/styles/variables';

.width {
  padding: 1.5rem;
  width: 100%;
}

.field {
  margin-bottom: 0;
}

.inline {
  margin-right: 1rem;
  margin-left: .5rem;
}

.wrapper{
  padding-top: 76px;
  min-width: 1450px;

  .sectionContainer {
    max-width: none;
    width: auto;
  }
}

.tableContainer {
  border: 1px solid $gray-500;
  border-radius: 0.5rem;
  .tableRow {
    &:not(:last-child) {
      border-bottom: 1px solid $gray-500;
    }
    &.tableHeader {
      background: $gray-300;
      border-radius: 0.5rem 0.5rem 0 0;
      font-weight: 600;
    }
    .tableCell {
      align-items: center;
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      justify-content: space-between;
      overflow: hidden;
      padding: 1rem 0.75rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:not(:last-child) {
        border-right: 1px solid $gray-500;
      }
      &.xSmall { width: 5rem; }
      &.small { width: 8rem; }
      &.medium { width: 10rem; }
      &.large { width: 11rem; }
      &.xLarge { width: 13.5rem; }
      > a, > span {
        overflow: hidden;
        padding-right: 0.25rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.noValue {
          color: $gray-600;
        }
      }
      a {
        color: $primary;
      }
      button {
        flex: 0;
        font-weight: 500;
        height: 36px;
        margin-left: 0.5rem;
        padding: 0.5rem;
        @include media-breakpoint-up(lg) {
          padding-right: 0.75rem;
        }
        &:first-child { margin-left: 0; }
        &.iconOnly, &.textOnly, &[class*="Overflow_button"] {
          padding-right: 0.5rem;
          span {
            font-size: 1.25rem;
            margin: 0 !important;
          }
        }
        .icon.button{
          font-size: 1.25rem;
          @include media-breakpoint-up(lg) {
            margin-right: 0.5rem;
          }
          + span {
            margin-left: 0;
            padding: 0;
          }
        }
      }
    }
  }
}

.editionDetails {
  gap: 1rem;
}

.editionEditor {
  background: $gray-300;
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 1rem;
  .editionAttribute {
    padding: 0.25rem 0;
    width: 50%;
    label {
      font-weight: 600;
      margin-right: 0.5rem;
    }
    input {
      border: 1px solid $gray-500;
      border-radius: 0.5rem;
      padding: 0.25rem 0.5rem;
      &:active, &:focus, &:focus-visible {
        border-color: $primary;
        outline: none;
      }
    }
    [class*="-control"] {
      box-sizing: border-box;
      &:active, &:focus, &:focus-visible, &:hover {
        border-color: $primary;
        outline: none;
      }
    }
  }
  button {
    margin-top: 0.5rem;
  }
}

.editionImage {
  background: $gray-300;
  border-radius: 0.5rem;
  height: 320px;
  margin-top: 1rem;
  min-width: 580px;

  img {
    border-radius: 0.5rem;
    height: 100%;
    object-fit: fill;
    width: 100%;
  }
}

.ordersListSectionHeader {
  margin-bottom: 0.5rem;
}

.sectionHeaderSubtitle {
  padding-bottom: 1rem;
  label {
    font-weight: 600;
    margin: 0 6px 0 0;
  }
  [class^="Field"] {
    margin: 0;
  }
  [class*="Value"] {
    margin: 0;
    padding: 0 1rem;
    width: 13rem;
  }
}

.csvExportContainer {
  align-items: center;
  border-right: 1px solid $gray-500;
  display: flex;
  flex-direction: row;
  margin-right: 1.5rem;
  padding-right: 1.5rem;
  input {
    border: 1px solid $gray-500;
    border-radius: 0.5rem;
    height: 44px;
    margin-right: 0.5rem;
    padding: 0.75rem;
  }
}

.listEmptyState {
  align-items: center;
  color: $gray-700;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  padding: 3rem;
  text-align: center;
}

.red {
  color: $red;
}
.danger {
  color: $red;
  &:hover {
    background: $red;
  }
}

// Tag Details

.popupTitle {
  background: #FFFFFF;
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 1.25rem 2rem;

  h2 {
    margin: 0;
  }

  button {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 0.5rem;
  }
}

.popupContent {
  background-color: $white;
  border-radius: 0 0 0.75rem 0.75rem;

  img {
    max-width: 100%;
  }
}

.popupBodySection {
  border-top: 1px solid $gray-500;
  padding: 0.75rem 0;
  &:first-child {
    border: none;
    padding-top: 0;
  }
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  label {
    color: $gray-700;
    font-size: 0.75rem;
    font-weight: 600;
  }
  button {
    flex: 0;
    font-weight: 500;
    height: 36px;
    margin-left: 0.5rem;
    padding: 0.5rem;
    @include media-breakpoint-up(lg) {
      padding-right: 0.75rem;
    }
    &:first-child { margin-left: 0; }
    &.iconOnly, &.textOnly, &[class*="Overflow_button"] {
      padding-right: 0.5rem;
      span {
        font-size: 1.25rem;
        margin: 0 !important;
      }
    }
    .icon.button{
      font-size: 1.25rem;
      @include media-breakpoint-up(lg) {
        margin-right: 0.5rem;
      }
      + span {
        margin-left: 0;
        padding: 0;
      }
    }
  }
  .id {
    display: flex;
    flex-grow: 1;
    min-width: 0;
    padding-left: 0.25rem;
    div {
      background: $gray-300;
      border-radius: 0.25rem;
      display: inline-block;
      font-family: monospace;
      font-size: 14px;
      overflow: hidden;
      padding: 2px 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .dotDivider {
    margin: 0 0.5rem;
  }
  .noValue {
    color: $gray-600;
  }
  .engraving {
    flex-grow: 1;
    &:first-child {
      border-right: 1px solid $gray-500;
      margin-right: 1rem;
      padding-right: 1rem;
    }
  }
  .orderNumber {
    color: $gray-700;
    font-size: 14px;
  }
  .addressLabel {
    border: 2px dashed $gray-500;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    padding: 1rem;
  }
  .addressOptions {
    gap: 0.5rem;
  }
  .rewards {
    margin-bottom: 0.5rem;
  }
}

.popupFooter {
  > button {
    margin-top: 1rem !important;
  }
}
