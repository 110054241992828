@import "src/styles/variables";

.brandColor {
    margin: 1rem 1.25rem;

    :global {
        .icon-pico {
            font-size: 50px;
            .path2 {
                &:before {
                    color: $primary;
                    margin-left: 0;
                }
            }
        }
    }
}

.heading {
    margin: 0 auto 0 0 !important;
    padding-left: 15px;

    h1 {
        color: $black;
        font-size: 1.125rem;
    }

    @include media-breakpoint-up(lg) {
        line-height: 2rem;
        h1 {
          margin-top: -3px;
          font-size: 1.25rem;
        }
    }
}

.mainNavBar {
    height: 64px !important;
    position: fixed;
    top: 0;
    transition: 0.2s;
    z-index: 1020;

    @include media-breakpoint-up(lg) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        height: 72px !important;
        left: 72px;
    }
}

.loading {
    div {
        width: 30px;
        height: 30px;
        margin-top: -15px;
    }
}
.redColor {
    color: $red;
}

.bigIcon {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.mobileCta {
    svg {
        cursor: pointer;
        margin-right: 1rem;
    }
}
