@import 'src/styles/variables';

.popupContainer{
    padding: 1.5rem 2rem 2rem;
}

.popup{
    max-width: 432px;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-down(sm) {
        width: calc(100% - 2rem) !important;
    }
}
