@import './dependencies';

.leftNavbar {
    position: fixed;
    box-shadow: none;
    // this it to cover the Intercom menu so the buttons are clickable
    // in mobile. It can be removed when Intercom is removed.
    // + 1 more than intercom zindex
    z-index: 1039; // zendesk installed
    top: 0;
    bottom: 0;
    // because of the navbar has width: fit-content; property and it's top priorty
    width: 72px !important;
    height: auto !important;
    background-color: $white;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    transition: 0.2s;
    @include media-breakpoint-down(md) {
        background-color: white;
        transition: 400ms;
        transform: translateX(-102%);
        height: 100vh !important;
        border-radius: 0 8px 8px 0;
        width: -webkit-fit-content !important;
        width: fit-content !important;
        top: 0;
        margin-top: 0;

        &.isOpen {
            transform: translateX(0%);
            // navbar mobile override
            max-width: 280px;
            width: calc(100% - 20px) !important;
            // this it to cover the Intercom menu so the buttons are clickable
            // in mobile. It can be removed when Intercom is removed.
            // + 1 more than intercom zindex
            z-index: 2147483003;
            box-shadow: 0px 32px 32px 16px rgba(0, 0, 0, 0.24);
        }
    }
    .mobileHeader {
        width: 100%;
        padding: 1.25rem 1rem;
        box-shadow: 0px 1px 0px 0px $gray-400;
    }

    .fullWidth {
        width: 100%;
    }
    .closeButton {
        width: 36px;
        height: 36px;
        border: 1px solid $gray-500;
        padding: 0;
        box-shadow: none;
    }
    .links {
        width: 100%;
        padding-top: 0.5rem;
        @include media-breakpoint-down(lg) {
            padding: 0.5rem;
        }
    }

    .item {
        margin: 0.5rem;
        font-size: 3.5rem;
        border-radius: 50%;
        @include media-breakpoint-down(md) {
            margin: 0.25rem 0;
            padding: 0.625rem 0.5rem;
            width: 100%;
            border-radius: 0.5rem;
            font-size: 2rem;
        }
        &--fixSize {
            > div svg {
              @include media-breakpoint-down(md) {
                width: 32px;
                height: 32px;
              }
            }

            > span > div {
                height: 3.5rem;
                width: 3.5rem;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    justify-content: flex-start;
                    height: 2.5rem;
                }
            }
        }
        &--anchor {
            text-decoration: none;
        }
        &.active {
            color: $purple;
            .iconPassive {
                &:before {
                    color: $btn-link-disabled-color;
                }
            }
            .iconActive {
                &:before {
                    color: $purple;
                }
            }
        }
    }
}

.itemTooltip {
    border-radius: 0.75rem;
    margin-left: -0.5rem;
    padding: 0.75rem 1rem;
    background-color: white;
}

.mobileTitle {
    @include media-breakpoint-down(md) {
        font-size: 1rem;
        font-weight: 600;
        margin-right: 1rem;
        margin-left: 0.5rem;
    }
}

.animationArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -2rem;
    font-size: 2rem;

    &.isOpen {
        transform: translateY(-50%) scaleX(-1);
    }

    &:not(.isOpen) {
        animation: jump 2s infinite;
    }

    @keyframes jump {
        0% {
            right: -2rem;
        }
        50% {
            right: -2.5rem;
        }
    }
}
.brandIcon {
    height: 72px;
    width: 100%;
    cursor: pointer;

    .border {
        border-right: 1px solid #414044;
        width: 1px;
        height: 32px;
    }
}

.badge {
    @include media-breakpoint-down(md) {
        bottom: 0;
        right: 0;
    }
}

.betaBadge {
    @include media-breakpoint-down(md) {
        :global {
            .badgeText {
                right: -30px;
                bottom: 10px;
            }
        }
    }
}

.brandColor {
    position: relative;
    border-radius: 0;
    color: $primary;
    padding: 10px 0 10px 20px;

    @include media-breakpoint-up(lg) {
      padding: 16px 0 16px 20px;
    }

    svg {
        fill: $primary;
    }

    &:hover {
        background: none;
        opacity: 1;
    }
}

.mobileMenu {
    @include containerWidth;
}

.submenuItem {
    border-radius: 0.5rem;
    flex-shrink: 0;
    font-weight: bold;
    line-height: 1.25rem;
    margin-right: 1.25rem;
    padding: 0.75rem 1rem;

    &:hover {
      cursor: default;
    }
}
.menu {
    position: fixed;
    top: 64px;
    z-index: 4;
    box-shadow: none;
    border-bottom: 1px solid $gray-400;
    height: 76px;
    padding: 1rem 1.5rem;
    overflow-x: auto;
    overflow-y: hidden;

    @include media-breakpoint-up(lg) {
      top: 72px;
    }
}

.icon-messages {
    position: relative;
}

.notificationAlert {
    position: absolute;
    background: #ff4440;
    height: 1rem !important;
    width: 1rem !important;
    border: 2px solid white;
    border-radius: 50%;
    top: 7px;
    right: 5px;
}



.mobileNavbar {
  background:white;
  position: fixed;
  bottom: 0;
  height: $mobile-navbar-height !important;
  width: 100% !important;
  display: block;
  border-top: 1px solid $gray-400;
  // should be top of the layers
  z-index: 999;

  .mobileNavbarWrapper{
    justify-content: space-around;
    padding-top: calc(0.5rem - 1px);
    padding-bottom: calc(0.5rem + env(safe-area-inset-bottom));
    width: 100%;
  }

  .item {
    font-size: 56px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &.active {
        color: $purple;
        border-radius: 50%;
        .iconPassive {
            &:before {
                color: $btn-link-disabled-color;
            }
        }
        .iconActive {
            &:before {
                color: $purple;
            }
        }
    }
  }
  .mobileIcon {
    font-size: 56px;
  }
}
.account {
  padding: 1rem;
  @include media-breakpoint-down(md) {
    padding: 0rem;
    .marginLeft {
      margin-left: 0.75rem;
    }
  }
  .communityName {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    @include media-breakpoint-up(md) {
      overflow: hidden;
      white-space: normal;
      width: auto;
    }
  }


}
.divider{
  margin: 7px 0;
  span{
      display: none;
  }
}
.accountName {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: $gray-700;
  margin-bottom: 0;

  &.email{
    margin-left: 0.5rem;
  }
}
.headerAvatar{
  border: 2px solid #FAFAFA
}
.customLink{
  // width: 56px;
  // height: 56px;
  svg{
    width: 45px;
    height: 45px;
  }
}
.switchAccountHeader{
  margin: 1rem 12px 12px 12px;
  .text{
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 2px;
    color: rgba(18, 10, 32, 1);

  }
  .backIcon{
    font-size: 24px;
    color: rgba(18, 10, 32, 1);
    margin-right: 8px;
  }
}
.searchWrapper{
  input{
    margin: 12px;
  }

}
.switchIcon {
  font-size: 24px;
  margin: 0.5rem;
}
.switchAccountName{
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}


.switchAccountUsername{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
  color: #AAA7B0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}


.overflowElement{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 99999999;
  overflow: hidden;
}

.scrollableArea {
  height: calc(100vh - 20px);
  overflow-y: scroll;
}
.optionWrapper {
  margin: 0.5rem 6px;
  .option{
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
}

.optionIcon{
  margin-right: 0.5rem !important;
}
