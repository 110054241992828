@import "src/styles/variables";

.guide{
    margin-bottom: 1.5rem;

    font-size: 1rem;
    line-height: 1.25rem;
    color: $black;

    display: flex;

    p{
        margin-bottom: 1rem;
    }
}